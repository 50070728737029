<template>
	<div class="table_img_horse" @click="$emit('click')" v-if="internalSrc">
		<img
			v-if="type === 'image'"
			:src="internalSrc"
			:class="customClass"
			width="100%"
		/>
		<iframe
			v-else
			:src="internalSrc"
			:class="customClass"
			frameborder="0"
		/>
	</div>
	<div v-else class="table_img_horse"><img  :src="defaultMedia" :class="customClass" /></div>
</template>

<script>
export default {
	name: 'OfflineMedia',
	props: {
		defaultMedia: null,
		filename: String,
		src: String,
		customClass: String
	},
	data: () => ({
		blob: null,
		internalSrc: null
	}),
	computed: {
		type() {
			return this.blob && this.blob.type.startsWith('image') ? 'image' : 'other'
		}
	},
	watch: {
		filename() {
			this.loadMedia()
		}
	},
	created() {
		this.loadMedia()
	},
	beforeDestroy() {
		this.revokeBlob()
	},
	methods: {
		async loadMedia() {
			if (!this.filename) {
				return
			}

			// Supprimer l'ancien BLOB pour libérer la RAM
			this.revokeBlob()

			this.blob = await this.$sync.loadOnDemandFile(this.filename)
			if (this.blob) {
				this.internalSrc = window.URL.createObjectURL(this.blob)
				this.$emit('update:src', this.internalSrc)
			}
		},
		revokeBlob() {
			if (this.internalSrc) {
				window.URL.revokeObjectURL(this.internalSrc)
			}
		}
	}
}
</script>